$yellowlight : #fcf4e7;
$yellow : #e1bc65;
$dark : #404042;
$green : #38786b;

$beige: #f2e8d3;
$lightbeige: #fffef8;
$red: #8a0f0f;
$darkred: #3e180f;

//responsive variables
$iphone : 540px;
$tablet : 880px;
$desktop : 1199px;

// fonts
$cormoran : 'Cormorant', serif;
$labelleaurore : 'La Belle Aurore', cursive;
$roboto : 'Roboto Condensed', sans-serif;
$sourcesans : 'Source Sans Pro', sans-serif;

html{
	overflow-x: hidden;
	&.blocked{
		overflow: hidden;
	}
}
body{
	font-family: $sourcesans;
	-webkit-font-smoothing: subpixel-antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	&.blocked{
		overflow: hidden;
	}
}
.no-scroll{
	overflow-y: hidden !important;
}
h1{
	font-family:$cormoran;
	font-size: 85px;
	line-height: 100px;
	color: #efeff1;
	margin-bottom: 50px;
	@media screen and (max-width: $desktop) {
		font-size: 65px;
		line-height: 80px;
	}
	@media screen and (max-width: $iphone) {
		font-size: 40px;
		line-height: 48px;
	}
}
h2{
	font-family:$cormoran;
	font-size: 90px;
	line-height: 100px;
	color: $beige;
	@media screen and (max-width: $desktop) {
		font-size: 70px;
		line-height: 80px;
	}
	@media screen and (max-width: $iphone) {
		font-size: 55px;
		line-height: 58px;
	}
}
h3{
	font-family: "regent_pro";
	font-size: 35px;
	line-height: 45px;
	color: #efeff1;
}
h4{
	font-family: $roboto;
	font-size: 24px;
	line-height: 38px;
	color: $red;
	text-transform: uppercase;
	margin-bottom: 25px;
	@media screen and (max-width: $iphone) {
		font-size: 21px;
	}
}
p{
	font-family: $sourcesans;
	font-size: 16px;
	line-height: 30px;
	color: $darkred;
}
i{
	font-style: italic;
}
.clr{
	clear: both;
}
.left{
	float: left;
	width: 50%;
}
.right{
	float: right;
	width: 50%;
}
.wrap{
	position: relative;
	max-width: 1175px;
	width: calc(100% - 100px);
	margin: auto;
	padding: 0 50px;
	@media screen and (max-width: $iphone) {
		padding:0 20px;
	}
	&.larger{
		max-width: 1425px;
	}
}
.v-align{
	position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.center{
	position: absolute;
  top: 50%;
	left: 50%;
  transform: translate(-50%,-50%);
}
.yellow{
	background-color: $lightbeige;
}
.full{
	width: 100%;
}
.mobile-only{
	display: none;
	@media screen and (max-width: $iphone) {
		display: block;
	}
}
.menuicon, #hamburger{
	display: none;
}
.menuicon {
	@media screen and (max-width: $tablet) {
		display: block;
	}
	position: absolute;
	top: 30px;
	left: 50px;
	z-index: 999999;
	cursor: pointer;
	height: 40px;
	@media screen and (max-width: $iphone) {
		top:30px;
		left: -10px;
	}
	span {
		display: block;
		top: 50%;
		width: 30px;
		height: 3px;
		background-color: $beige;
		position: relative;
		-webkit-transition-duration: 0;
		-moz-transition-duration: 0;
		-ms-transition-duration: 0;
		-o-transition-duration: 0;
		transition-duration: 0;
		-webkit-transition-delay: 0;
		-moz-transition-delay: 0;
		-ms-transition-delay: 0;
		-o-transition-delay: 0;
		transition-delay: 0;
		@media screen and (max-width: $iphone) {
			width: 40px;
			height: 3px;
		}
		&::after, &::before {
			display: block;
			content: '';
			position: absolute;
			width: 30px;
			height: 3px;
			background-color: $beige;
			-webkit-transition-property: margin, -webkit-transform;
			-webkit-transition-duration: .5s;
			-moz-transition-duration: .5s;
			-ms-transition-duration: .5s;
			-o-transition-duration: .5s;
			transition-duration: .5s;
			-webkit-transition-delay: .5s, 0;
			-moz-transition-delay: .5s, 0;
			-ms-transition-delay: .5s, 0;
			-o-transition-delay: .5s, 0;
			transition-delay: .5s, 0;
			@media screen and (max-width: $iphone) {
				width: 40px;
				height: 3px;
			}
		}
		&::before {
			margin-top: -10px;
			@media screen and (max-width: $iphone) {
				margin-top: -10px;
			}

		}
		&::after {
			margin-top: 10px;
			@media screen and (max-width: $iphone) {
				margin-top: 10px;
			}
		}
	}
}
#hamburger:checked ~ .menu-menu-principal-container {
	left: 0;
}
#hamburger:checked ~ .mobile-nav-mask{
	opacity: 0.6;
	visibility: visible;
}
#hamburger:checked ~ .menuicon {
	span {
		background-color: rgba(0,0,0,0);
		&::before, &::after {
			margin-top: 0px;
			-webkit-transition-delay: 0, .3s;
			-moz-transition-delay: 0, .3s;
			-ms-transition-delay: 0, .3s;
			-o-transition-delay: 0, .3s;
			transition-delay: 0, .3s;
		}
		&::before {
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		&::after {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	}
}
.header{
	position: absolute;
	height: 150px;
	width: 100%;
	z-index: 990;
	transition: background 0.3s;
	@media screen and (max-width: $tablet) {
		background: #340809;
		height: 105px;
		top: 0;
		position: fixed;
	}
	.logo{
		position: absolute;
		left:30px;
		top:35px;
		width:60px;
		@media screen and (max-width: $tablet) {
			left:50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			top: 20px;
			width: 45px;
			&.mob{
				display: block !important;
			}
		}
		&.mob{
			display: none;
		}
	}

	.social{
		position: absolute;
		top: 35px;
		right: 238px;
		transition: top 0.3s;
		@media screen and (max-width: $tablet) {
			display: none
		}
		@media screen and (max-width: $iphone) {
			display: none;
		}
		img{
			max-width: 16px;
			padding: 0 3px;
			@media screen and (max-width: $tablet) {
				padding: 0 15px;
			}
			@media screen and (max-width: $iphone) {
				padding:0 8px;
			}
		}
	}
	.lang{
		position: absolute;
		right: 30px;
		top: 90px;
		display: block;
		text-transform: uppercase;
		color: #ffffff;
		display: block;
		padding: 5px 0;
		font-weight: 600;
		transition: top 0.3s;
		@media screen and (max-width: $tablet) {
			right:60px;
			top:40px;
			color: #e5e3d7;
		}
		@media screen and (max-width: $iphone) {
			right:0px;
			top:40px;
		}
	}
	.menu-menu-principal-container{
		@media screen and (max-width: $tablet) {
			position: fixed;
			left:-100%;
			right: inherit;
			top:0;
			padding-top: 120px;
			background-color: $darkred;
			width: 45%;
			min-width: 280px;
			height: 100vh;
			-webkit-transition:all .3s ease-in-out;
			transition:all .3s ease-in-out;
			z-index: 99999;
			overflow: scroll;
		}
		@media screen and (max-width: $iphone) {
			padding-top: 0;
		}
	}
	.menu{
		position: absolute;
		right: 110px;
		top: 90px;
		padding-right: 75px;
		display: block;
		transition: top 0.3s;
		@media screen and (max-width: $tablet) {
			right:inherit;
			position:relative;
			top:0;
			padding-right: 0;
		}
		@media screen and (max-width: $iphone) {
			padding-top: 80px;
			ul{
				overflow: scroll;
			}
		}
		li{
			display: inline-block;
			margin: 0 0 0 55px;
			@media screen and (max-width: $tablet) {
				display: block;
				margin: 0;
				padding: 30px 0 30px 50px;
				border-bottom:1px solid darken($dark,5%);
				&:nth-child(4){
					margin-bottom:0;
					border:0;
				}
				// &:last-child{
				// 	background-color:lighten($darkred,5%);
				// 	margin:0;
				// 	border:0;
				// 	padding-left: 0;
				// }
			}
			@media screen and (max-width: $iphone) {
				padding: 30px 0 30px 20px;
			}
			a{
				color: #ffffff;
				display: block;
				padding: 5px 0;
				font-weight: 600;
				font-size: 16px;
				font-family: $roboto;
				text-transform: uppercase;

			}
			&.book{
				a{
					background: url(../assets/images/rec-menu.png) no-repeat center;
					background-size: contain;
					padding: 25px 0;
					text-align: center;
					position: absolute;
					top: -28px;
					right: 0;
					@media screen and (max-width: $tablet) {
						position: relative;
						top: inherit;
						right: inherit;
					}
				}
			}
		}
	}
	.mobile-nav-mask{
		display: none;
		@media screen and (max-width: $tablet) {
			display: block;
			position: fixed;
			top:0;
			left:0;
			width:100%;
			height:100vh;
			background-color: $dark;
			z-index: 999;
			opacity: 0;
			visibility: hidden;
			-webkit-transition:all .3s ease-in-out;
			transition:all .3s ease-in-out;
		}
	}
	&.dark{
		.lang{
			color: $red;
			@media screen and (max-width: $tablet) {
				color:#e5e3d7;
			}
		}
		.menuicon {
			span{
				background-color: $red;
				@media screen and (max-width: $tablet) {
					background-color:#e5e3d7;
				}
				&:after,&:before{
					background-color: $red;
					@media screen and (max-width: $tablet) {
						background-color:#e5e3d7;
					}
				}
			}
		}
		.menu{
			li{
				a{
					color: $red;
					@media screen and (max-width: $tablet) {
						color: $yellowlight;
					}
				}
				&.book{
					text-align: center;

					a{
						background: url(../assets/images/rec-light.png) no-repeat center;
						background-size: contain;
						padding: 25px 0;
						text-align: center;
						position: absolute;
						top: -28px;
						right: 0;
						@media screen and (max-width: $tablet) {
							background: url(../assets/images/rec-menu.png) no-repeat center;
							background-size: contain;
							position: relative;
							top: inherit;
							right: inherit;
						}
					}
				}
			}
		}
	}
	&.follow{
		position: fixed;
		height: 125px;
		background: rgba(0,0,0,0.7);
		background: -moz-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 81%, rgba(0,0,0,0) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.7)), color-stop(81%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0)));
		background: -webkit-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 81%, rgba(0,0,0,0) 100%);
		background: -o-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 81%, rgba(0,0,0,0) 100%);
		background: -ms-linear-gradient(top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 81%, rgba(0,0,0,0) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 81%, rgba(0,0,0,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
		@media screen and (max-width: $tablet) {
			background: #340809 !important;
			height: 105px;
		}
		.social{
			top: -30px;
			@media screen and (max-width: $tablet) {
				display: none;
			}
			@media screen and (max-width: $iphone) {
				display: none;
			}
		}
		.menu-menu-principal-container{
			@media screen and (max-width: $tablet) {
				top:0;
			}
		}
		.menu{
			top: 47px;
			@media screen and (max-width: $tablet) {
				top:0;
			}
		}
		.lang{
			top: 47px;
			@media screen and (max-width: $tablet) {
				top:40px;
			}
		}
	}
	&.dark.follow{
		background: rgba(255,255,255,0.7);
		background: -moz-linear-gradient(top, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 81%, rgba(255,255,255,0) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.7)), color-stop(81%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,0)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 81%, rgba(255,255,255,0) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 81%, rgba(255,255,255,0) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 81%, rgba(255,255,255,0) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 81%, rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
	}
}
.banner{
	position: relative;
	height: 100vh;
	min-height: 800px;
	background-size: cover;
	background-position: center;
	width:100%;
	@media screen and (max-width: $iphone) {
		min-height:auto;
		height: calc(100vh - 105px);
		margin-top:105px;
	}
	.overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		background: rgba(0, 0, 0, 0.5);
		color: rgba(0, 0, 0, 0.5);
	}
	.center{
		text-align: center;
		max-width: 90%;
		width: calc(100% - 100px);
		padding:0 50px;
		margin: 0 auto;
		h3{
			font-family: $labelleaurore;
			font-size: 66px;
			@media screen and (max-width: $desktop) {
				font-size: 55px;
			}
			@media screen and (max-width: $iphone) {
				font-size: 36px;
			}
		}
		@media screen and (max-width: $iphone) {
			width: calc(100% - 40px);
		}
	}
}
#home{
	.book-home{
		background: #f4f3ed;
		//display: block;
		text-align: center;
		font-weight: 900;
		padding: 25px 0;
		color: $red;
		text-transform: uppercase;
		font-size: 20px;
	}
	.content{
		position: relative;
		height: auto;
		&.yellow{
			background-image: url(../assets/images/cuillere.png);
			background-repeat: no-repeat;
			background-position: calc(100% + 50px) 200px;
			z-index: 99;
			background-size: 525px;
			@media screen and (max-width: $desktop) {
				background-position: calc(100% + 50px) 100px;
			}
			@media screen and (max-width: $tablet) {
				padding-bottom: 125px;
				position: relative;
				height: 980px;
			}
			@media screen and (max-width: $iphone) {
				background-image: none;
				height: auto;
				padding-bottom: 60px;
			}
			.wrap{
				@media screen and (max-width: $tablet) and (min-width: $iphone) {
					position: absolute;
					float: left;
			    -webkit-transform: scaleY(-1);
			    transform: scaleY(-1);
					width: 100%;
					& > div{
						position: relative;
						float: left;
				    -webkit-transform: scaleY(-1);
				    transform: scaleY(-1);
					}
				}
			}
			h2{
				position: absolute;
				left:-15px;
				top: 225px;
				@media screen and (max-width: $desktop) {
					top: 125px;
				}
				@media screen and (max-width: $tablet) {
					color: $darkred;
				}
				@media screen and (max-width: $iphone) {
					left: -4px;
					top:150px;
					color: $beige;
					display: none;
				}
			}
			.wrap{
				@media screen and (max-width: $iphone) {
					width: 100%;
					padding: 20px;
					box-sizing: border-box;
				}
			}
			.left{
				padding-top: 560px;
				margin-bottom: -130px;
				width: calc(100% - 530px);
				padding-right: 50px;
				@media screen and (max-width: $desktop) {
					padding-top: 460px;
				}
				@media screen and (max-width: $tablet) {
					width: 80%;
					padding-right: 0;
					padding-top: 50px;
					margin-bottom: 10px;
				}
				@media screen and (max-width: $iphone) {
					padding-top:60px;
					width: 100%;
					margin-bottom: 0;
				}
				img{
					max-width: 380px;
					width: 100%;
					@media screen and (max-width: $iphone) {
						max-width: 100%;
					}
				}
			}
			.right{
				padding-top: 430px;
				width: 480px;
				@media screen and (max-width: $desktop) {
					padding-top: 330px;
				}
				@media screen and (max-width: $tablet) {
					left: 30%;
					width: 50%;
				}
				@media screen and (max-width: $iphone) {
					left:0;
					width: 100%;
					padding-top: 40px;
				}
			}
		}
		&.second{
			background-color: #560d11;
			@media screen and (max-width: $tablet) {
				padding-bottom: 80px;
				.wrap{
					width:calc(100% - 40px);
				}
			}
			@media screen and (max-width: $iphone) {
				padding-bottom: 0;
			}
			.left{
				padding-top: 450px;
				width: 380px;
				h4,p{
					color: $beige;
				}
				@media screen and (max-width: $tablet) {
					padding-top: 125px;
				}
				@media screen and (max-width: $iphone) {
					padding-top: 100px;
					left:0;
					width: 90%;
				}
			}
			.right{
				padding-top: 300px;
				width: calc(100% - 430px);
				padding-left: 50px;

				@media screen and (max-width: $tablet) {
					width: 85%;
					padding-top: 100px;
				}
				@media screen and (max-width: $iphone) {
					width: 100%;
					padding-left: 0;
					//padding-right: 20px;
				}
				img{
					width: 100%;
					max-width: 700px;
					text-align: right;
					float: right;
				}
			}
		}
		&.third{
			background-color: #560d11;
			z-index: 99;
			padding: 200px 0;
			background-image: url(../assets/images/accroche-2.png);
			background-repeat: no-repeat;
			background-position: 0 0;
			@media screen and (max-width: $desktop) {
				margin-top: 0;
				background-image: url(../assets/images/accroche-mobile.png);
				padding: 100px 0 180px;
				background-position: 20% 50%;
				background-size: auto calc(100% - 80px);
			}
			@media screen and (max-width: $tablet) {
				background-position: 50% 25%;
				background-size: auto 75%;
			}
			@media screen and (max-width: $iphone) {
				background-position: 50% 50%;
				.wrap{
					width:100%;
					padding:0;
				}
			}
			.right{
				width: 450px;
				text-align: left;
				h2{
					color: #37080a;
					@media screen and (max-width: $desktop) {
						color: $beige;
					}
				}
				@media screen and (max-width: $tablet) {
					padding:20px;
					text-align: center;
					margin:0 auto;
					position: relative;
					float: none;
				}
				@media screen and (max-width: $iphone) {
					width: calc(100% - 40px);

				}
			}
		}
	}
	.bottom-slider{
		width: 100%;
		height: auto;
		position: relative;
		background: #340809;
		img{
			width: 100%;
		}
	}
}
.footer{
	background: #340809;
	color: $beige;
	height: 270px;
	.wrap{
		@media screen and (max-width: $iphone) {
			width: calc(100% - 40px);
			max-width: none;
		}
	}
	@media screen and (max-width: $tablet) {
		height: auto;
		padding-bottom: 40px;
		text-align: center !important;
		width: 100% !important;
	}
	p{
		color: $beige;
	}
	.row{
		position: absolute;
		top: 115px;
		@media screen and (max-width: $tablet) {
			position: relative;
			top:0;
			text-align: center !important;
			width: 100% !important;
		}
	}
	.logo{
		width: 57px;
		left:50px;
		top: 100px;
		@media screen and (max-width: $tablet) {
			display: none;
		}

		img{
			width: 100%;
			max-width: 120px;
		}
	}
	.phone{
		float: left;
		padding-left: 125px;
		margin-top: 90px;
		p{
			margin-top: 15px;
			line-height: 22px;
			color: $beige;
		}
		@media screen and (max-width: $tablet) {
			float: none;
			padding-left: 0;
			margin-top: 0;
			width: 100% !important;
			padding-top:60px;
		}
		span{
			font-weight: 900;
			font-size: 24px;
			a:link,a:visited,a:active{
				color: $beige;
			}
		}
	}
	.social{
		top: 130px;
		width: 100%;
		text-align: center;
		left:0;
		color: $beige;
		@media screen and (max-width: $tablet) {
			float: none;
			top:0;
			text-align: left;
			padding-top: 40px;
			clear: both;
		}
		img{
			max-width: 20px;
			margin-bottom: -5px;
			padding: 0 5px;
		}
	}
	.copy{
		right:50px;
		@media screen and (max-width: $tablet) {
			right:inherit;
			padding-top: 30px;
			width: 50%;
			margin-bottom: 10px;
		}
		p{
			line-height: 24px;
		}
	}
}

#about{
	h1{
		font-family: 'Yeseva One', cursive;
	}
	.yellow{
		.wrap{
			@media screen and (max-width: $iphone) {
				width: 100%;
				box-sizing: border-box;
			}
		}
	}
	.text{
		background: #560d11;
		padding: 160px 230px;
		text-align: center;
		margin-top: -120px;
		background-image: url(../assets/images/tag.png);
		background-repeat: no-repeat;
		background-position: -40px 40px;
		background-blend-mode: multiply;
		h4,p{
			color: $beige;
		}
		@media screen and (max-width: $desktop) {
			padding: 125px;
		}
		@media screen and (max-width: $tablet) {
			padding:60px 80px;
		}
		@media screen and (max-width: $iphone) {
			background-image: none;
			padding: 20px;
		}
	}
	.andre{
		padding-top: 250px;
		padding-bottom: 450px;
		@media screen and (max-width: $desktop) {
			padding-top: 125px;
			padding-bottom: 250px;
		}
		@media screen and (max-width: $tablet) {
			padding-bottom: 125px;
			padding-top: 0;
		}
		@media screen and (max-width: $iphone) {
			background-size: contain;
			background-position: left bottom;
			padding-top: 50px;
			padding-bottom: 50px;
		}
		.right{
			width: 100%;
			h2{
				font-family: 'Yeseva One', cursive;
				color: #e5e2d1;
				font-size: 80px;
				line-height: 80px;
			}
			@media screen and (max-width: $desktop) {
				h2{
					font-size: 70px;
					line-height: 70px;
				}
			}
			@media screen and (max-width: $tablet) {
				width: 100%;
				padding-top: 125px;
				h2{
					font-size: 60px;
					line-height: 60px;
					color: $darkred;
				}
			}
			@media screen and (max-width: $iphone) {
				width: 100%;
				padding-top: 0;
				h2{
					font-size: 40px;
					line-height: 40px;
				}
			}
			h5{
				font-family: $labelleaurore;
				font-size: 50px;
				display: block;
				padding-top: 95px;
				padding-bottom: 20px;
				color: $dark;
				@media screen and (max-width: $desktop) {
					padding-top: 65px;
				}
				@media screen and (max-width: $iphone) {
					font-size: 30px;
					padding-top: 45px;
					padding-bottom: 0;
				}
			}
			p{
				font-style: italic;
			}
		}
	}
	.content{
		.left{
			max-width: 385px;
			padding-top: 230px;
			@media screen and (max-width: $desktop) {
				padding-top: 0;
			}
			@media screen and (max-width: $tablet) {
				padding-top: 100px;
				padding-right: 30px;
				box-sizing: border-box;
				margin-bottom: 50px;
			}
			@media screen and (max-width: $iphone) {
				float: none;
				width: 100%;
				margin-bottom: 0;
			}
		}
		.right{
			width: calc(100% - 485px);
			padding-left: 100px;
			margin-top: -230px;
			@media screen and (max-width: $desktop) {
				margin-top: -125px;
			}
			@media screen and (max-width: $tablet) {
				margin-top: 100px;
				width: 50%;
				padding-left: 0;
				margin-bottom: 50px;
			}
			@media screen and (max-width: $iphone) {
				float: none;
				width: 100%;
				padding-top: 50px;
				margin-bottom: 25px;
			}
			img{
				width:100%
			}
		}
		.bottom{
			width: 100%;
			max-width: 930px;
			padding: 250px 0;
			@media screen and (max-width: $desktop) {
				padding: 125px 0;
			}
			@media screen and (max-width: $tablet) {
				display: none;
			}
		}
	}
}

#promos{
	.banner{
		position: relative;
		height: 630px;
		min-height: 630px;
		@media screen and (max-width: $iphone) {
			height: calc(100vh - 105px);
			min-height:auto;
		}
		.wrap{
			height: 630px;
			@media screen and (max-width: $iphone) {
				width: 100%;
				height: calc(100vh - 105px);
				min-height:auto;
				padding-left: 0;
			}
			h1{
				bottom:15px;
				left:50px;
				position: absolute;
				width:60%;
				max-width: 700px;
				@media screen and (max-width: $desktop) {
					max-width: 500px;
				}
				@media screen and (max-width: $iphone) {
					left:0;
					bottom: 50%;
					-webkit-transform: translateY(50%);
					transform: translateY(50%);
					text-align: center;
					width: calc(100% - 20px);
					margin-bottom: 0;
				}
			}
		}
	}
	.promos{
		position: relative;
		.wrap{
			@media screen and (max-width: $iphone) {
				width: calc(100% - 40px);
			}
		}
		.center{
			width:calc(100% - 50px);
			max-width: 375px;
			@media screen and (max-width: $tablet) {
				max-width: none;
				position: relative;
				width: 100%;
				transform: none;
				top:0;
				left: 0;
			}
		}
		.left{
			position: relative;
			min-height: 500px;
			margin-bottom: 115px;
			width: 60%;
			background-size: cover;
			background-position: center center;
			@media screen and (max-width: $tablet) {
				float: none;
				width:100%;
				min-height:0;
				&.background{
					min-height: 450px;
					@media screen and (max-width: $iphone) {
						min-height: 300px;
					}
				}
				&:first-child{
					max-width: none;
					width: 70%;
					position: relative;
					top:0;
					left: 0;
					transform: none;
					padding-top: 0;
				}
			}
			@media screen and (max-width: $iphone) {
				margin-bottom: 50px;
				&:first-child{
					width: 90%;
				}
			}
		}
		.right{
			position: relative;
			min-height: 500px;
			margin-bottom: 115px;
			background-size: cover;
			background-position: center center;
			width: 40%;
			@media screen and (max-width: $tablet) {
				float: none;
				width:100%;
				min-height: 0;
				&.background{
					min-height: 450px;
					@media screen and (max-width: $iphone) {
						min-height: 300px;
					}
				}
			}
			@media screen and (max-width: $iphone) {
				margin-bottom: 50px;
			}
			&.first{
				height: 700px;
				width: 40%;
				position: absolute;
				right: 0;
				top: -185px;
				@media screen and (max-width: $tablet) {
					position: relative;
					top:0;
					width: 100%;
				}
				@media screen and (max-width: $iphone) {
					height: 450px;
				}
			}

		}
	}
}

#book{
	background-color: #f8f3e9;
	.banner{
		height: auto;
		min-height: 0;
		h1{
			padding-top: 300px;
			color: $darkred;
			@media screen and (max-width: $tablet) {
				text-align: left;
				padding-top: 150px;
			}
			@media screen and (max-width: $iphone) {
				margin-bottom: 0;
				padding-top: 75px;
			}
		}
	}
	h4{
		color: $darkred;
	}
	.wrap:nth-child(4),.wrap:nth-child(5){
		@media screen and (max-width: $iphone) {
			width: 100%;
			padding: 0;
		}
	}
	.coord{
		padding: 50px 0 100px 0;
		.col{
			float: left;
			width: 33.333%;
			max-width: 300px;
			@media screen and (max-width: $iphone) {
				float: none;
				max-width: 100%;
				width: 100%;
				margin-top: 50px;
			}
			h4{
				margin-bottom: 10px;
			}
			a {
				color: $darkred;
			}
		}
	}
	.acf-map{
		margin-top:-100px;
		margin-bottom: 200px;
		height: 680px;
		@media screen and (max-width: $tablet) {
			margin-top: 0;
			margin-bottom: 50px;
		}
		@media screen and (max-width: $iphone) {
			height: 450px;
			margin-bottom: 0;
		}
	}
}
#menu{
	min-height: 100vh;
  max-height: 100%;
  height: auto;
	overflow-y: auto !important;
	position: fixed;
	width: 100%;
	z-index: 999;
	right: -100%;
	top:0;
	transition: right 0.6s;

	&.show{
		right: 0;
	}
	.header{
		position: absolute;
		background: transparent;
		.social{
			top: 35px;
			@media screen and (max-width: $tablet) {
				display: none !important;
			}
		}
		.menu{
			top: 90px;
		}
		.lang{
			top: 87px;
			@media screen and (max-width: $tablet) {
				display: none;
			}

		}
	}
	.content{
		background-image: url('../assets/images/bg-menu.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		margin-top: 380px;
		position: relative;
		height: auto;
		padding-bottom: 150px;
		@media screen and (max-width: $tablet) {
			margin-top:250px;
			background-position: center;
			background-size: cover;
		}
		@media screen and (max-width: $iphone) {
			margin-top: 200px;
			background-position: top center;
		}
		.wrap{
			@media screen and (max-width: $iphone) {
				width: 100%;
				box-sizing: border-box;
			}
			.toggle-menus{
				width: 250px;
				height: auto;
				border: 2px solid $green;
				padding: 50px 0;
				@media screen and (max-width: $tablet) {
					float: none;
					width: 100%;
				}
				&.desktop{
					@media screen and (max-width: $tablet) {
						display: none;
					}
				}
				&.tablet{
					display: none;
					padding:0;
					border:0;
					position: relative;

					select{
						display: none;
					}
					.select-selected{
						background-color: $red;
						height: 80px;
						line-height: 80px;
						color: #38786b;
				    font-weight: 600;
				    font-size: 20px;
				    line-height: 80px;
						text-transform: uppercase;
						font-family: $roboto;
						outline: none;
						@media screen and (max-width: $iphone) {
							height: 35px;
							line-height: 35px;
						}
					}
					.select-selected:after {
					  position: absolute;
					  content: "";
					  top:39px;
					  right: 40px;
					  width: 0;
					  height: 0;
					  border: 6px solid transparent;
					  border-color: #fff transparent transparent transparent;
						@media screen and (max-width: $iphone) {
							top:40px;
							right: 20px;
						}
					}
					.select-selected.select-arrow-active:after {
					  border-color: transparent transparent #fff transparent;
					  top: 34px;
						@media screen and (max-width: $iphone) {
							top:35px;
						}
					}
					.select-items div,.select-selected {
					  color: #ffffff;
					  padding: 25px 16px;
					  border: 1px solid transparent;
					  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
					  cursor: pointer;
					}
					.select-selected {
						@media screen and (min-width: 768px) {
							padding: 0px 16px !important;
						}
					}
					.select-items {
					  position: absolute;
					  background-color: $darkred;
					  top: 100%;
					  left: 0;
					  right: 0;
					  z-index: 99;
						font-family: $roboto;
						text-transform: uppercase;
					}
					.select-hide {
					  display: none;
					}
					.select-items div:hover, .same-as-selected {
					  background-color: rgba(0, 0, 0, 0.1);
					}
					@media screen and (max-width: $tablet) {
						display: block;
					}
				}
				ul{
					padding: 0 45px;
					text-align: center;
					li{
						margin: 50px 0;
						cursor: pointer;
						color: #977229;
						text-transform: uppercase;
						text-align: center;
						font-family: $roboto;
						font-size: 16px;
						line-height: 22px;
						display: inline-block;
						&:hover,&.active{
							color: $red;
							position: relative;
							&:after{
								content:"";
								width:35px;
								height: 40px;
								background-image: url(../assets/images/arrow.png);
								background-repeat: no-repeat;
								background-size: contain;
								position: absolute;
								top:5px;
								right: -37px;
							}
							&:before{
								content:"";
								width:35px;
								height: 40px;
								background-image: url(../assets/images/arrow.png);
								background-repeat: no-repeat;
								background-size: contain;
								position: absolute;
								top:-22px;
								left: -37px;
								-webkit-transform: rotate(180deg);
								transform: rotate(180deg);
							}
						}
					}
				}
			}
			.left:first-child{
				background-image: url(../assets/images/fond-boite-menu.png);
				background-position: 0 0;
				background-size: contain;
				background-repeat: no-repeat;
				border:none;
				height: 450px;
			}
			.right{
				width: calc(100% - 379px);
				margin-left: 125px;
				height: auto;
				padding:50px 0;
				@media screen and (max-width: $tablet) {
					width: 90%;
					margin: 0 auto;
					float: none;
				}
				.open{
					width: 35px;
					height: 35px;
					background: red;
					position: absolute;
					top: -45px;
					right: 50px;
					cursor: pointer;
					background: url(../assets/images/close.png) no-repeat center;
					background-size: contain;
					@media screen and (max-width: $tablet) {
						top:-80px;
						z-index: 9999;
					}
					@media screen and (max-width: $iphone) {
						right:50%;
						-webkit-transform:translateX(50%);
						transform:translateX(50%);
						top:-80px;
					}
				}
				.toggle-menu{
					display: none;
					h3{
						color: $red;
					@media screen and (max-width: $tablet) {
						margin-top: 50px;
					}
					}
					.left,.right{
						@media screen and (max-width: $tablet) {
							float: none;
							margin:0;
						}
					}
					.right{
						@media screen and (max-width: $tablet) {
							padding-top: 0;
						}
					}
					&.visible{
						display: block;
					}
				}
				.note{
					font-family: $sourcesans;
					font-size: 18px;
					color: $darkred;
					margin-bottom: 25px;
					display: block;
				}
				h3{
					font-size: 50px;
					line-height: 60px;
					color: $darkred;
					display: block;
					padding-bottom: 25px;
					border-bottom: 1px solid #9d9c93;
					margin-top: 100px;
				}
				.left, .right{
					width: calc(50% - 25px);
					margin-right: 25px;
					padding-top: 50px;
					padding-bottom: 0;
					ul{
						font-size: 18px;
						font-family: $sourcesans;
						color: $darkred;
						line-height: 26px;
						li{
							margin-bottom: 25px;
							em{
								display: block;
								font-family: $sourcesans;
								font-weight: 100;
								font-style: italic;
								font-size: 16px;
							}
						}
					}
				}
				.right{
					margin-left: 25px;
					margin-right: 0;
				}
			}
		}
	}
}
